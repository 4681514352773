import * as React from 'react';
import styled from '@emotion/styled';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Typography as MuiTypography } from '@mui/material';
import CardContent from '@mui/material/CardContent';

export interface CardContentProps {
  title?: string | undefined;
  limit?: number | undefined;
  description?: string | undefined;
  hideDescription?: boolean | undefined;
  className?: string;
}

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  width: '100%',
  padding: `${theme.spacing(1)} !important`,
  backgroundColor: '#fff',
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    border: theme.theme_component_card_content_border || 'none',
    borderTop: 'none',
    pointerEvents: 'none',
  },
})) as typeof CardContent;

const CardTitle = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  color: '#000',
  margin: theme.spacing(0),
  lineHeight: '30px',
  marginBottom: theme.spacing(0.5),
}));

const getText = (val: string, limit: number) => {
  if (val !== '' && val !== null && val !== undefined && val?.length > limit) {
    return `${val.slice(0, limit - 3)}...`;
  }
  return val;
};

export const CollectionContentE = (props: CardContentProps) => {
  const { title, description, hideDescription, limit, className, ...other } = props;
  const body2Ref = React.useRef<HTMLInputElement | null>(null);
  return (
    <CardContentStyled className={className}>
      <CardTitle variant="caption" noWrap {...other}>
        {getText(title || '', limit || 30)}
      </CardTitle>
    </CardContentStyled>
  );
};
